<template>
  <div class="control">
    <div class="control-item" @click="getData">
      <van-icon name="replay" /> 刷新
    </div>
    <div class="control-item" @click="clear">
      <van-icon name="delete-o" /> 清空
    </div>
    <div class="control-item">
      <van-uploader :after-read="ocr">
        <van-icon name="down" style="transform: scaleY(-1)" /> 上传
      </van-uploader>
    </div>
    <div class="control-item" @click="saveImage">
      <van-icon name="down" /> 保存
    </div>
  </div>
  <div class="game-list">
    <div class="game-item" v-for="game in list" :key="game.session">
      <div class="game-title">
        <van-row>
          <van-col span="6">{{ game.session }}</van-col>
          <van-col span="18" style="text-align: left">
            {{ game.homeTeam }} VS {{ game.visitingTeam }}（{{ game.event }}）
          </van-col>
        </van-row>
      </div>
      <div class="game-info">
        <van-field
          type="text"
          label="胜负参考"
          placeholder="请输入比赛结果"
          v-model="game.result"
          @input="saveData"
          autocomplete
        />
      </div>
      <div class="game-info">
        <van-field
          type="text"
          label="比分参考"
          placeholder="请输入比分"
          v-model="game.score"
          @input="saveData"
          autocomplete
        />
      </div>
      <div class="game-info">
        <van-field
          type="text"
          label="进球数参考"
          placeholder="请输入进球数"
          v-model="game.num"
          @input="saveData"
          autocomplete
        />
      </div>
    </div>
  </div>
  <div class="table-box" ref="imageWrapper">
    <div
      :class="index == 0 ? 'table-title' : 'table-info'"
      v-for="(game, index) in [title, ...pagination]"
      :key="game.session"
    >
      <van-row>
        <van-col class="border-right" span="2">{{ game.session }}</van-col>
        <van-col class="border-right" span="2">{{ game.event }}</van-col>
        <van-col class="border-right" span="6">
          {{ game.homeTeam }} VS {{ game.visitingTeam }}
        </van-col>
        <van-col class="border-right" span="6">{{ game.result }}</van-col>
        <van-col class="border-right" span="4">{{ game.score }}</van-col>
        <van-col span="4">{{ game.num }}</van-col>
      </van-row>
    </div>
  </div>
  <van-dialog
    v-model:show="dialog"
    title="请调整每页显示的条数"
    @confirm="showImgs"
  >
    <div style="padding: 30px; text-align: center">
      <van-stepper v-model="pageSize" />
    </div>
  </van-dialog>
</template>

<script>
import { toRefs } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import useIndex from "./module/base";
import { Dialog } from "vant";
export default {
  setup() {
    let {
      getData,
      getLocalData,
      showImgs,
      saveData,
      clear,
      pagination,
      data,
      ocr,
      saveImage,
    } = useIndex();
    // 页面初始化
    onMounted(() => {
      const nowDay = new Date().toJSON().split("T")[0];
      const saveDay = localStorage.getItem("time");
      if (saveDay != nowDay) {
        getData("2022-02-10");
        localStorage.setItem("time", nowDay);
      } else {
        getLocalData();
      }
    });

    return {
      ...toRefs(data),
      pagination,
      getData,
      saveData,
      showImgs,
      clear,
      ocr,
      saveImage,
    };
  },
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
};
</script>

<style lang="scss" scoped>
.control {
  position: fixed;
  z-index: 66;
  top: 0;
  height: 44px;
  display: flex;
  justify-content: space-between;
  padding: 0 5vw;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
}
$border: 1px solid #cecece;
.table-box {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(-200%);
  width: 1080px;
  height: auto;
  text-align: center;
  line-height: 2;
  font-size: 14px;
  border: $border;
  .border-right {
    border-right: $border;
  }
  .table-title {
    background-color: rgb(207, 22, 22);
    color: #fff;
  }
  .table-info {
    border-top: $border;
  }
}
.game-list {
  height: calc(100% - 44px);
  position: relative;
  top: 44px;
  padding-bottom: 50px;
  background: #f0f0f0;
  .game-item {
    background: #fff;
    margin-top: 10px;
    text-align: center;
    line-height: 1.8;
    .game-title {
      background-color: rgb(207, 22, 22);
      color: #fff;
    }
    .game-info {
      border-top: $border;
      .game-label {
        border-right: $border;
      }
    }
  }
  overflow: auto;
}
</style>
