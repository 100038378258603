import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { Row, Col, Field, NavBar, Icon, Uploader, Stepper } from "vant";

createApp(App)
  .use(router)
  .use(Row)
  .use(Col)
  .use(Field)
  .use(NavBar)
  .use(Icon)
  .use(Uploader)
  .use(Stepper)
  .mount("#app");
