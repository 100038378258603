export default function (data) {
  let newArray = [];
  let count = 0;
  let flag = false;
  const reg = {
    zh: /^(?:[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0])+$/,
    week: /周.\d+/,
    vs: /vs/i,
    num: /^\d+$/,
    res: /胜|平|负/,
    score: /\d-\d/,
  };
  const getKey = (words, count) => {
    let key = "";
    try {
      if (reg.week.test(words)) {
        key = "session";
      } else if (reg.res.test(words)) {
        key = "result";
        newArray[count][key] = words;
      } else if (reg.score.test(words)) {
        let scoreReg = /\d{1}.\d{1}/g;
        key = "score";
        if (newArray[count][key]) {
          newArray[count][key] = (newArray[count][key] + words)
            .match(scoreReg)
            .join(" ");
        } else {
          newArray[count][key] = words.match(scoreReg).join(" ");
        }
      } else if (reg.zh.test(words)) {
        key = "event";
        newArray[count][key] = words;
      } else if (reg.vs.test(words)) {
        let [homeTeam, visitingTeam] = words.toLowerCase().split("vs");
        newArray[count]["homeTeam"] = homeTeam;
        newArray[count]["visitingTeam"] = visitingTeam;
        key = "team";
      } else if (reg.num.test(words)) {
        key = "num";
        if (newArray[count][key]) {
          newArray[count][key] = (newArray[count][key] + words)
            .toString()
            .split("")
            .join(" ");
        } else {
          newArray[count][key] = words.toString().split("").join(" ");
        }
      }
    } catch (e) {
      console.log(e);
    }
    return key;
  };
  data.forEach((item) => {
    if (reg.week.test(item.words)) {
      if (!flag) {
        flag = true;
      } else {
        count++;
      }
      newArray[count] = {
        session: item.words,
      };
    } else {
      if (!flag) return;
      getKey(item.words, count);
    }
  });
  return newArray;
}
