import { reactive } from "@vue/reactivity";
import { Toast, ImagePreview } from "vant";
import { computed, getCurrentInstance, nextTick } from "@vue/runtime-core";
import html2canvas from "html2canvas";
import { ref } from "vue";
import formatData from "./formatData";
let qs = require("qs");
export default function () {
  const { proxy } = getCurrentInstance();
  const data = reactive({
    list: [],
    imgUrl: [],
    title: {
      event: "赛事",
      gameTime: "时间",
      homeTeam: "主队",
      num: "进球数参考",
      result: "胜负参考",
      score: "比分参考",
      session: "编号",
      visitingTeam: "客队",
    },
    page: 1,
    pageSize: 10,
    dialog: false,
  });
  // 筛选有效数据
  const showList = computed(() => {
    return data.list.filter((game) => game.result || game.num || game.score);
  });
  // 获取最大页码
  const maxPage = computed(() =>
    Math.ceil(showList.value.length / data.pageSize)
  );
  // 生成图片数据分页
  const pagination = computed(() => {
    return showList.value && showList.value.length > 0
      ? showList.value.slice(
          (data.page - 1) * data.pageSize,
          data.page * data.pageSize
        )
      : [];
  });
  // 将数据存储到本地缓存
  const saveData = () => {
    localStorage.setItem(
      "data",
      JSON.stringify(data.list && data.list.length ? data.list : [])
    );
  };
  const clear = () => {
    data.list = data.list.map((game) => {
      delete game.result;
      delete game.score;
      delete game.num;
      return game;
    });
    saveData();
  };
  // 获取比赛数据
  const getData = () => {
    Toast.loading({
      message: "数据加载中...",
      forbidClick: true,
      duration: 0,
    });
    const nowDay = new Date().toJSON().split("T")[0];
    fetch("https://fastapi.tobeat.top/getFootballData?day=" + nowDay)
      .then((response) => response.json())
      .then((res) => {
        Toast.clear();
        const newData = res.map((val1) => {
          let item = data.list.filter((val2) => val2.session == val1.session);
          if (item && item.length > 0) {
            val1.score = item[0].score;
            val1.num = item[0].num;
            val1.result = item[0].result;
          }
          return val1;
        });
        data.list = newData;
        saveData();
      });
  };
  // 获取本地缓存数据
  const getLocalData = () => {
    let localData = localStorage.getItem("data");
    data.list = localData ? JSON.parse(localData) : [];
  };
  const toImage = async () => {
    const canvas = await html2canvas(proxy.$refs.imageWrapper);
    if (canvas) {
      let dataURL = canvas.toDataURL("image/png");
      data.imgUrl[data.page - 1] = dataURL;
      if (data.page >= maxPage.value) {
        Toast.clear();
        return ImagePreview(data.imgUrl);
      } else {
        data.page++;
        nextTick(() => {
          toImage();
        });
      }
    } else {
      toImage();
    }
  };
  // 点击生成图片
  const showImgs = () => {
    Toast.loading({
      message: "图片生成中...",
      forbidClick: true,
      duration: 0,
    });
    data.page = 1;
    toImage();
  };
  let accessToken = ref("");
  const getAccessToken = () => {
    return new Promise((reslove) => {
      fetch(`https://fastapi.tobeat.top/getOcrAccessToken`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((res) => {
          accessToken.value = res.access_token;
          localStorage.setItem(
            "ocr_access_token",
            JSON.stringify({
              time: new Date().getTime() + res.expires_in * 1000,
              value: accessToken.value,
            })
          );
          reslove(!0);
        });
    });
  };
  const isAccessToken = async () => {
    let loc_token = localStorage.getItem("ocr_access_token");
    if (loc_token) {
      loc_token = JSON.parse(loc_token);
      if (new Date().getTime() > loc_token.time) {
        return await getAccessToken();
      } else {
        accessToken.value = loc_token.value;
        return !0;
      }
    } else {
      return await getAccessToken();
    }
  };
  const fillListData = (list) => {
    let arr = formatData(list);
    data.list.forEach((game, index) => {
      const item = arr.filter((item) => {
        let size = 0;
        if (item.session == game.session) size++;
        if (item.event == game.event) size++;
        if (item.homeTeam == game.homeTeam.toLocaleLowerCase()) size++;
        if (item.visitingTeam == game.visitingTeam.toLocaleLowerCase()) size++;
        return size >= 2;
      });
      if (item && item.length > 0) {
        data.list[index].result = item[0].result;
        data.list[index].score = item[0].score;
        data.list[index].num = item[0].num;
      }
    });
    saveData();
  };
  const ocr = async (file) => {
    Toast.loading({
      message: "图片识别中...",
      forbidClick: true,
      duration: 0,
    });
    await isAccessToken();
    if (file.content) {
      fetch(
        `https://aip.baidubce.com/rest/2.0/ocr/v1/accurate_basic?access_token=${accessToken.value}`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: qs.stringify({
            table_border: "normal",
            image: file.content,
          }),
        }
      )
        .then((response) => response.json())
        .then((res) => {
          Toast.clear();
          fillListData(res.words_result);
        });
    } else {
      Toast.fail("上传失败,请重新上传");
    }
  };
  const saveImage = () => {
    if (!showList.value || showList.value.length <= 0) {
      return Toast.fail("请先分析比赛");
    }
    data.dialog = true;
  };
  return {
    getLocalData,
    maxPage,
    pagination,
    getData,
    showImgs,
    saveData,
    clear,
    data,
    ocr,
    saveImage,
  };
}
